import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'
import { theme } from '@chakra-ui/pro-theme'

// const fonts = { mono: `'Menlo', monospace` }

const breakpoints = {
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em',
}

// 2. Extend the theme to include custom colors, fonts, etc
const colors =  { 
  ...theme.colors, 
  brand: theme.colors.blue 
};

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: true,
}

// const myTheme = extendTheme({ colors, theme })
const myTheme = extendTheme(
  {
    colors,
    config
  },
  theme,
)

export default myTheme

import { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import { Wallet } from '../context/Wallet';
import { UserProvider } from '../context/User';
import '@fontsource/inter/variable.css';
import '../styles/wallet_styles.css';

import myTheme from '../theme';


function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider resetCSS theme={myTheme} >
      <UserProvider>
        <Wallet>
          <Component {...pageProps} />
        </Wallet>
      </UserProvider>
    </ChakraProvider>
  )
}

export default MyApp

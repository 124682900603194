import React, { Context, createContext, useEffect, useState } from "react";
import { useRouter } from "next/router";

export type User = {
  id: string;
  dc_id: string;
  dc_username: string;
  dc_discriminator: string;
  dc_avatar: string;
  dc_banner: string;
  username: string;
  jwt: string;
};

interface IUserContext {
  user: User | null;
  setUser: any;
  userLoaded: boolean;
  setUserLoaded: any;
  logOut: Function;
  isLoggedIn: boolean;
  isAdmin: boolean;
}

export const UserContext = React.createContext<IUserContext>(null);

export const UserProvider: React.FC = ({ children }) => {
  const [userLoaded, setUserLoaded] = useState<boolean>(false);
  const [user, setUser] = useState<User>(null);
  const router = useRouter();

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user_data") as string) || null);
    setUserLoaded(true);
  }, []);

  const logOut = async () => {
    setUser(null);
    setUserLoaded(false);
    localStorage.removeItem("user_data");
    router.push("/");
  };

  const isAdmin = Boolean(user?.id) && user.dc_id === "111882212428754944";

  useEffect(() => {
    if (user) localStorage.setItem("user_data", JSON.stringify(user));
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn: Boolean(user?.id),
        user,
        setUser,
        userLoaded,
        setUserLoaded,
        logOut,
        isAdmin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

import React, { FC, useMemo } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
} from "@solana/wallet-adapter-wallets";

import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

import { clusterApiUrl } from "@solana/web3.js";

// Default styles that can be overridden by your app
require("@solana/wallet-adapter-react-ui/styles.css");

type Props = {
  network?: WalletAdapterNetwork;
};

const defaultProps = {
  network: WalletAdapterNetwork.Mainnet,
};

export const Wallet: FC<Props> = ({ network, children }) => {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  //const network = solanaNetwork;
  
  // You can also provide a custom RPC endpoint.
  const endpoint = "https://solana-mainnet.rpc.extrnode.com/9f9d1721-b295-42a1-bcf5-fda7caefe33c"
  // const endpoint =
  // "https://081a1dua2hvc1de2ptkt9uidgqwosqfq1blenzvlopx3opom7fxbf0eoqdqy9.xyz2.hyperplane.dev";
  //const endpoint = "https://api.helius.xyz/v0/rpc?api-key=2ba93245-c919-4d4c-85ad-7c4839975c13";
  // const endpoint = "https://foxesnhfvrchfgtvygq9qxxww4aiz.xyz2.hyperplane.dev/";
  //const endpoint = "https://api.devnet.solana.com";
  // const endpoint = "https://solana-api.projectserum.com";
  //const endpoint = "https://solana-mainnet-rpc.allthatnode.com";
  //"https://rpc.theindex.io/mainnet-beta/74ac8509-44a0-4320-bbf4-6bede54b5d3f";
  // const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      {/* <WalletProvider wallets={wallets} autoConnect> */}
      <WalletProvider wallets={wallets}>
        {/* @ts-ignore */}
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

Wallet.defaultProps = defaultProps;
